/* App.css */
body, h1, h2, ul {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
}

@font-face {
  font-family: commodore;
  src: url(Commodore-64-v6.3.TTF);
}

.App {
  /* text-align: center; */
  font-family: commodore, "Courier New", monospace;
  line-height: 1.2;
}

header {
  background-color: black;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 20px;
}

nav a {
  color: #61dafb;
  margin: 0 10px;
  text-decoration: none;
}

section {
  margin: 20px 10px;
}

#name {
  margin-left: 10px;
}

ul {
  list-style-type: "* ";
}

li {
  margin-left: 5px;
}